export async function fetchCatalog() {
  const res = await fetch("/json/catalog.json");
  const data = res.json();
  return data;
}

export async function fetchSofa(name) {
  const res = await fetch("/json/"+name+".json");
  const data = res.json();
  return data;
}


export default fetchCatalog;