import { Map, Placemark, TrafficControl } from '@pbe/react-yandex-maps';

const EmbeddedMap = () => {
    let baloons = [
        {
            name: "ТЦ Победа, 2-ой этаж, секция 226",
            coords: [53.197550,50.248458]
        },
        {
            name: "ТЦ МегаМебель, секция 117",
            coords: [53.191487, 50.167360]
        },
    ];

    return (
        <div className="">
            <Map
                defaultState={{
                center: [53.206039, 50.190030],
                zoom: 11,
                controls: ["zoomControl", "fullscreenControl"],
                }}
                width="100%"
                height="400px"
                modules={["control.ZoomControl", "control.FullscreenControl"]}
            >
                <Placemark
                    modules={["geoObject.addon.balloon"]}
                    defaultGeometry={baloons[0].coords}
                    properties={{
                        balloonContentBody:
                        baloons[0].name,
                    }}
                />
                <Placemark
                    modules={["geoObject.addon.balloon"]}
                    defaultGeometry={baloons[1].coords}
                    properties={{
                        balloonContentBody:
                        baloons[1].name,
                    }}
                />
                <TrafficControl options={{ float: "right" }} />
            </Map>
        </div>
    );
};

export default EmbeddedMap;