import "./BreadCrump.css"

import { Link } from "react-router-dom";

const BreadCrump = ({link}) => {
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-3">
                <li className="breadcrumb-item bread-text"><Link className="bread-text" to="/">Главная</Link></li>
                <li className="breadcrumb-item bread-text" aria-current="page">{link}</li>
            </ol>
        </nav>
    );
};

export const SofaBreadCrump = ({link}) => {
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-3 pt-3">
                <li className="breadcrumb-item bread-text"><Link className="bread-text" to="/">Главная</Link></li>
                <li className="breadcrumb-item bread-text"><Link className="bread-text" to="/catalog">Диваны</Link></li>
                <li className="breadcrumb-item bread-text active" aria-current="page">{link}</li>
            </ol>
        </nav>
    );
};


export default BreadCrump;