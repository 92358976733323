const StoreInfo = ({store}) => {
    return (
        <div className="text-center pb-3">
                <h2 className="col-auto"><strong>{store.name}</strong></h2>
                <hr className="col mx-3 mt-2 border-2 opacity-75"/>
            <h4>{store.address}</h4>
            <h4>{store.description}</h4>
            <h5>{store.time}</h5>
            <h5>{store.phone}</h5>
        </div>
    );
};

export default StoreInfo;