import "./Contacts.css";

const Contacts = () => {
    return (
      <div className="superNav border-bottom border-top contacts-font bg-light">
        <div className="container">
            <div className="row justify-content-end p-1">
            <div className="col-lg-auto col-md-auto col-sm-auto centerOnMobile">
                <span className="d-none d-lg-inline-block d-md-inline-block d-sm-inline-block d-xs-none me-3"><strong>Ежедневно с 10:00 до 20:00</strong></span>
                <span className="me-3"><strong>8 (905) 303-93-95</strong></span>
                <span className="me-3"><strong>8 (987) 929-78-72</strong></span>
                <span className="d-none d-lg-inline-block d-md-inline-block d-sm-inline-block d-xs-none me-3"><strong>mail@sofa-room63.ru</strong></span>
            </div>
            </div>
            </div>
        </div>
      );
}

export default Contacts;