import React from "react";

import { useEffect } from "react";

import { YMaps } from "@pbe/react-yandex-maps";

import EmbeddedMap from "../components/Map/Map";
import Header from "../components/PageHeader/PageHeader";
import StoreInfo from "../components/StoreInfo/StoreInfo";
import BreadCrump from "../components/BreadCrump/BreadCrump";

const Stores = () => {
    let page_name = "Салоны"

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
    let stores = [
        {
            name: "ТЦ Победа",
            address: "г. Самара, ул. 22-ого Партсъезда, 1/2",
            description: "2-ой этаж, секция 226", 
            time: "ежденевно 10:00-20:00",
            phone: "8 (905) 303-93-95"
        },
        {
            name: "ТЦ МегаМебель",
            address: "г. Самара, ул. Партизанская, 17Ж",
            description: "секция 117", 
            time: "ежедневно 10:00-20:00",
            phone: "8 (987) 929-78-72"
        
        }
    ]

    return (
        <main className="container pb-3">
            <Header name={page_name}/>
            <BreadCrump link={page_name}/>
            <div className="row justify-content-evenly">
                <div className="col-sm pt-3 mx-3">
                    <StoreInfo store={stores[0]} />
                </div>
                <div className="col-sm pt-3 mx-3">
                    <StoreInfo store={stores[1]} />
                </div>
            </div>
            <h3 className="text-center pb-3">Почта для связи: mail@sofa-room63.ru</h3>
            <YMaps >
                <EmbeddedMap />
            </YMaps>
        </main>
    );
};

export default Stores;