import './Footer.css';

import logo from '../../assets/dark-logo.svg';
// import vk_logo from '../../assets/vk-logo.svg';
// import inst_logo from '../../assets/instagram-logo.svg';

import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="footer-section">
        <div className="container">
            <div className="footer-cta pt-5 pb-5">
                <div className="row">
                    <div className="col-xl-4 col-md-4 mb-30">
                        <div className="single-cta">
                            <i className="bi bi-geo-alt-fill"></i>
                            <div className="cta-text">
                                <h4>ТЦ Победа, 2 этаж, секция 226</h4>
                                <div className="footer-text mt-25">
                                    <li><span>г. Самара, ул. 22-го Партсъезда, 1/2</span></li>
                                    <li><span>Ежедневно с 10:00 до 20:00</span></li>
                                </div>
                            </div>
                        </div>
                        <div className="single-cta">
                            <i className="bi bi-geo-alt-fill"></i>
                            <div className="cta-text">
                                <h4>ТЦ МегаМебель, секция 117</h4>
                                <div className="footer-text mt-25">
                                    <li><span>г. Самара, ул. Партизанская, 17Ж</span></li>
                                    <li><span>Ежедневно с 10:00 до 20:00</span></li>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 mb-30">
                        <div className="single-cta">
                            <i className="bi bi-telephone-fill"></i>
                            <div className="cta-text">
                                <h4>Связаться с нами</h4>
                                <li><span>8 (905) 303-93-95</span></li>
                                <li><span>8 (987) 929-78-72</span></li>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 mb-30">
                        <div className="single-cta">
                            <i className="bi bi-envelope-fill"></i>
                            <div className="cta-text">
                                <h4>Написать нам</h4>
                                <span>mail@sofa-room63.ru</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-content pb-5">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 mb-50">
                        <div className="footer-widget">
                            <div className="footer-logo">
                                <a href="index.html"><img src={logo} className="img-fluid" alt="sofa-room"/></a>
                            </div>
                            <div className="footer-text">
                                <p>Уют и комфорт вашего дома с нашими стильными диванами. 
                                    Погрузитесь в мягкость и качество наших моделей, созданных для вашего идеального отдыха. 
                                    Приходите и выбирайте лучшее для вашего интерьера!</p>
                            </div>
                            {/* <div className="footer-social-icon">
                                <span>Мы в социальных сетях</span>
                                <Link to="/"><img src={vk_logo} alt="vk.com"/></Link>
                                <Link to="/"><img src={inst_logo} alt="vk.com"/></Link>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>Оплата и доставка</h3>
                            </div>
                            <div className="footer-text mb-25">
                                <p>Мы принимаем оплату картой и наличными. <br/> Возможна рассрочка.</p>
                            </div>
                            <div className="footer-text mb-25">
                                <p>Доставка по Самаре и Самарской области. <br/> Стоимость рассчитывается отдельно.</p>
                            </div>
                            <div className="footer-text mb-25">
                                <p>Подробнее узнавайте по телефону.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>Покупателю</h3>
                            </div>
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                <li><Link to="/catalog">Диваны</Link></li>
                                <li><Link to="/stores">Салоны</Link></li>
                                <li><Link to="/order">Как заказать?</Link></li>
                                <li><Link to="/delivery">Оплата и доставка</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright-area">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                        <div className="copyright-text">
                            <p>Copyright &copy; 2024. Все права защищены</p>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                        <div className="footer-menu">
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                <li><Link to="/catalog">Диваны</Link></li>
                                <li><Link to="/stores">Салоны</Link></li>
                                <li><Link to="/order">Как заказать?</Link></li>
                                <li><Link to="/delivery">Оплата и доставка</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    );
};

export default Footer;