import React from "react";
import { useEffect, useState } from "react";

import Spinner from 'react-bootstrap/Spinner';

import Header from "../components/PageHeader/PageHeader";
import ProductCard from "../components/ProductCard/ProductCard";
import BreadCrump from "../components/BreadCrump/BreadCrump";

import fetchCatalog from "../utils/fetchApi";

const Catalog = () => {
    let page_name = "Диваны"; 

    const [products, setProducts] = useState([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getData() {
          let res = await fetchCatalog();
          setProducts(res);
        }
        getData();
      }, []);
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    return (
        <main className="container">
            <Header name={page_name}/>
            <BreadCrump link={page_name}/>
            {loading 
            ? <div className="row d-flex justify-content-center mt-5 mb-5">
                <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
            </div>      
            : <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3 g-4 mb-5">
                <AllProducts products={products} />
            </div>
            }
        </main>
    );
};

function AllProducts({ products }) {
    return products?.map(
        (product) => { return <ProductCard product={product} key={product.id} />; }
    );
};

export default Catalog;