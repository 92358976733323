import React from "react";

import { useEffect } from "react";

import Header from "../components/PageHeader/PageHeader";

const Delivery = () => {
    let page_name_1 = "Оплата"; 
    let page_name_2 = "Доставка";
    
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
    return (
        <main className="container">
            <Header name={page_name_1}/>
            <h4 className="mb-2">Мы предлагаем различные варианты оплаты:</h4>
            <h5>Наличными при доставке</h5>
            <div>
            Общую стоимость заказа и особые условия доставки или подъема будут заранее обговорены и внесены в договор оплаты.
            При доставке необходимо проверить общее количество мебели, целостность упаковки и отсутствие внешних повреждений, далее, при предъявлении соответствующих документов (чек + накладная), производится оплата.
            </div>
            <h5>Банковские карты</h5>
            <div>
                Мы принимаем оплату любыми банковскими картами систем <strong>Visa, Mastercard и МИР</strong>.
            </div>
            <Header name={page_name_2}/>
            <div>
            Sofa-Room  - это  высокопрофессиональный подход к сервису. 
            Мы ценим время покупателей и предлагаем возможность определить удобное время доставки. 
            Мы готовы  доставить товар, произвести подъем и качественную сборку.
            </div>
            <div className="pb-5">
            Мы производим доставку по Самаре и Самарской области согласно установленным тарифам. 
            Также возможна доставка в другие регионы РФ, которая рассчитывается индивидуально, исходя из расстояния и специфики товара. 
            На сайте представлены ориентировочные сроки доставки.
            Сроки и стоимость доставки  можно уточнить  по телефону: 8 (905) 303-93-95
            </div>
        </main>
    );
};

export default Delivery;