import "./Navbar.css";

import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/dark-logo.svg";

const Navbar = () => {
    return (
        <header className={"header"}>
            <Navigations />
        </header>
    );
};

function Navigations() {
    const [isNavOpen, setIsNavOpen] = useState(false);

    function handleOpenNavigation() {
        setIsNavOpen(!isNavOpen);
    }

    function closeNavigation() {
      if (isNavOpen) {
        setIsNavOpen(false);
      }
    }

    return (
        <nav className={`my-nav container ${isNavOpen ? "nav-open" : ""}`}>
          <span className="brand-logo">
            <Link to="/"><img className="brand-logo" src={logo} alt="sofa-room"/></Link>
          </span>
          <div>
            <ul className="nav-link_container">
                <li className="my-nav-link">
                <NavLink to="/" onClick={closeNavigation}>Главная</NavLink>
                </li>
                <li className="my-nav-link">
                <NavLink to="/catalog" onClick={closeNavigation}>Диваны</NavLink>
                </li>
                <li className="my-nav-link">
                <NavLink to="/stores" onClick={closeNavigation}>Салоны</NavLink>
                </li>
                <li className="my-nav-link">
                <NavLink to="/order" onClick={closeNavigation}>Как заказать?</NavLink>
                </li>
                <li className="my-nav-link">
                <NavLink to="/delivery" onClick={closeNavigation}>Оплата и доставка</NavLink>
                </li>
            </ul>
          </div>
          <div className="nav-secondary_btn" onClick={handleOpenNavigation}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="nav-overlay"></div>
        </nav>
      );
}

export default Navbar;