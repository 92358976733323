import React from "react";
import { useEffect, useState } from "react";

import Carousel from 'react-bootstrap/Carousel';
import { YMaps } from "@pbe/react-yandex-maps";

import EmbeddedMap from "../components/Map/Map";
import Header from "../components/PageHeader/PageHeader";
import ProductCard from "../components/ProductCard/ProductCard";

import fetchCatalog from "../utils/fetchApi";
import MainHeader from "../components/MainHeader/MainHeader";

const Home = () => {
    const main_images = [
        "/assets/carolina/car-x-1.png", "/assets/nika/nik-x-4.png", "/assets/real/rea-x-2.png"
    ];

    const [products, setProducts] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    useEffect(() => {
        async function getData() {
          let res = await fetchCatalog();
          setProducts([res[0], res[6], res[9]]);
        }
        getData();
      }, []);

    return (
        <main className="container">
            <div className="row">
                <MainHeader />
                <div className="col-md-8 col-xl-8 my-2">
                    <ImagesCarousel images={main_images}/>
                </div>
            </div>
            <Header name="Топ продаж"/>
            <div className="row row-cols-1 row-cols-md-3 g-4 mb-5">
                <AllProducts products={products} />
            </div>
            <div className="pb-5">
            <Header name="Салоны"/>
            <YMaps>
                <EmbeddedMap/>
            </YMaps>
            </div>
        </main>
    );
};

function ImagesCarousel({images}) {
    let i = 0;
    return (
        <Carousel data-bs-theme="dark" className="mw-100" controls={false} >
            {images?.map(
                (image) => {
                    return (
                        <Carousel.Item key={i++}  interval={5000}>
                                <img src={image} className="d-block w-100 rounded" alt=""/>
                        </Carousel.Item>
                    );
                })
            }
        </Carousel>
    );
};

function AllProducts({ products }) {
    return products?.map(
        (product) => { return <ProductCard product={product} key={product.id} />; }
    );
};

export default Home;