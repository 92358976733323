import React from "react";

import { 
  Route,
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";


// Pages
import Home from "./pages/Home";
import Catalog from "./pages/Catalog";
import Order from "./pages/Order";
import Stores from "./pages/Stores";
import Delivery from "./pages/Delivery";

//Layout
import RootLayout from "./layout/Layout";
import Sofa from "./pages/Sofa";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route path="/catalog/" element={<Catalog />} />
      <Route path="/order/" element={<Order />} />
      <Route path="/stores/" element={<Stores />} />
      <Route path="/delivery/" element={<Delivery />} />
      <Route path="/sofa/:id" element={<Sofa />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Route>
  )
);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
