import { Outlet } from "react-router-dom";

import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer"
import Contacts from "../components/Contacts/Contacts";


function RootLayout() {
  return (
    <>
      <Contacts/>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
}

export default RootLayout;