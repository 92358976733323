import { Link } from "react-router-dom";

import "./ProductCard.css";

const ProductCard = ({ product }) => {
    let last_price;
    if (product?.last_price) {
        last_price = 
            <Link to={`/sofa/${product?.route_name}`} className="btn col-auto no-wrap">
                <strike>от {product?.last_price} ₽</strike>
            </Link>
    }

    return (
        <div className="col">
            <div className="card h-100 border-0">
                <Link className="bg-image hover-zoom" to={`/sofa/${product?.route_name}`}>
                    <img src={product?.image} className="card-img-top img-fluid rounded-top" alt={product?.name}/>
                    {/* TODO image overlay В НАЛИЧИИ */}
                </Link>
                <div className="card-body">
                    <h5 className="card-title">{product?.name}</h5>
                    <p className="card-text text-success mb-2"><i className="bi bi-check-all"></i> На заказ от {product?.production_time}</p>
                    <div className="row justify-content-end mx-2">
                        {last_price}
                        <Link to={`/sofa/${product?.route_name}`} className="btn btn-outline-dark btn-price col-auto no-wrap">
                            <strong>от {product?.price} ₽</strong>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
  );
};

export default ProductCard;