import Carousel from 'react-bootstrap/Carousel';

import "./ProductView.css";

const ProductView = ({product}) => {
    return (
        <div className="card mb-3 border-0" >
        <div className="row g-0">
            <div className="col-lg-12 col-xl-8">
                <ImagesCarousel images={product?.images} />
            </div>
            <div className="col-lg-12 col-xl-4">
            <div className="card-body">
                <h1 className="card-title">{product?.name}</h1>
                <p className="card-text text-success mb-2"><i className="bi bi-check-all"></i> На заказ от {product?.specs?.production_time}</p>
                <div className="row g-0 justify-content-start py-1">
                    <h3 className="col-auto price p-2 rounded-pill text-center"><strong>от {product?.price} ₽</strong></h3>
                    {(product?.last_price) ? <h3 className="col-auto last-price p-2"><strike><strong> от {product?.last_price} ₽</strong></strike></h3> : ""}
                </div>
                <hr/>
                <p className="card-text"><strong>Основные характеристики:</strong></p>
                <Specs specs={product?.specs}/>
            </div>
            </div>
        </div>
        <div className="row g-0 mt-3">
            <h2 className="card-text py-2"><strong>Все характеристики:</strong></h2>
            <AllSpecs specs={product?.specs}/>
        </div>
        </div>
    );
};

function ImagesCarousel({images}) {
    let i = 0;
    return (
        <Carousel data-bs-theme="dark" controls={false} interval={null}>
            {images?.map(
                (image) => {
                    return (
                        <Carousel.Item key={i++} interval={i === 0 ? 5000 : 3000}>
                                <img src={image} className="d-block w-100 rounded" alt=""/>
                        </Carousel.Item>
                    );
                })
            }
        </Carousel>
    );
};

function Specs({specs}) {
    return (
        <div className="row justify-content-end">
            <div className="col-9 card-text text-muted">Ширина, см:</div>
            <div className="col-3 card-text text-end">{specs?.width}</div>

            <div className="w-100"></div>

            <div className="col-9 card-text text-muted">Глубина, см:</div>
            <div className="col-3 card-text text-end">{specs?.depth}</div>

            <div className="w-100"></div>

            <div className="col-9 card-text text-muted">Высота, см:</div>
            <div className="col-3 card-text text-end">{specs?.height}</div>
            
            {specs?.sleep ? <div className="w-100"></div> :""}

            {specs?.sleep ? <div className="col-7 card-text text-muted">Cпальное место, см:</div> :""}
            {specs?.sleep ? <div className="col-5 card-text text-end">{specs?.sleep}</div> :""}

            <div className="w-100"></div>

            <div className="col-9 card-text text-muted">Конфигурация на заказ:</div>
            <div className="col-3 card-text text-end">Да</div>

            <div className="w-100"></div>

            <div className="col-9 card-text text-muted">Размер на заказ:</div>
            <div className="col-3 card-text text-end">Да</div>
        
            <div className="w-100"></div>

            <div className="col-9 card-text text-muted">Обивка на заказ:</div>
            <div className="col-3 card-text  text-end">Да</div>
        </div>
    );
};

function AllSpecs({specs}) {
    return (
        <table className="table table-hover fs-5">
        <tbody>
            <tr><th scope="row">Тип дивана:</th><td>{specs?.type}</td></tr>
            {specs?.mechanism ? <tr><th scope="row">Механизм:</th><td>{specs?.mechanism}</td></tr> : ""}
            <tr><th scope="row">Наполнитель:</th><td>{specs?.material}</td></tr>
            <tr><th scope="row">Ширина, см:</th><td>{specs?.width}</td></tr>
            <tr><th scope="row">Глубина, см:</th><td>{specs?.depth}</td></tr>
            <tr><th scope="row">Высота, см:</th><td>{specs?.height}</td></tr>
            {specs?.sleep ? <tr><th scope="row">Cпальное место, см:</th><td>{specs?.sleep}</td></tr> : ""}
            <tr><th scope="row">Бельевой короб:</th>{specs?.storage_box === true ? <td>Да</td> : <td>Нет</td>}</tr>
            <tr><th scope="row">Раскладывается:</th>{specs?.unfolding === true ? <td>Да</td> : <td>Нет</td>}</tr>
            <tr><th scope="row">Срок изготовления:</th><td>от {specs?.production_time}</td></tr>
            <tr><th scope="row">Конфигурация на заказ:</th><td>Да</td></tr>
            <tr><th scope="row">Размер на заказ:</th><td>Да</td></tr>
            <tr><th scope="row">Обивка на заказ:</th><td>Да</td></tr>
            <tr><th scope="row">Цвет на заказ:</th><td>Да</td></tr>
            <tr><th scope="row">Страна производства:</th><td>{specs?.country}</td></tr>
            <tr><th scope="row">Гарантия:</th><td>{specs?.guarantee} месяцев</td></tr>
        </tbody>
        </table>
    ); 
}

export default ProductView;