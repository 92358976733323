import "./PageHeader.css";

const Header = ({name}) => {
    return (
        <div className="container">
            <div className="row align-items-center justify-content-start">
                <div className="col-sm-auto col-auto p-0"><h2 className="pt-3 pb-3 header-color">{name}</h2></div>
                <div className="col-sm col"><hr className="mt-2 border-2 opacity-75 hr-color"/></div>
            </div>
        </div>
        
      );
}

export default Header;