import React from "react";
import { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";

import { SofaBreadCrump } from "../components/BreadCrump/BreadCrump";
import ProductView from "../components/ProductView/ProductView";

import { fetchSofa } from "../utils/fetchApi";

const Sofa = () => {
    const { id } = useParams();
    const [error, setError] = useState({});

    const [product, setProduct] = useState([]);

    useEffect(() => {
        async function getProduct(id) {
          let res = fetchSofa(id);
          res.catch((error) => {
            setError(error);
          });
          res.then((product) => {
            setProduct(product);
          });
        }
        getProduct(id);
      }, [id]);
    
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
    
    if (error.message) {
        return <Navigate to="/catalog" />;
    }

    return <main className="container">
        <SofaBreadCrump link={product?.name}/>
        <ProductView product={product} />
    </main>;
};

export default Sofa;