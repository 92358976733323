import React from "react";

import { useEffect } from "react";

import Header from "../components/PageHeader/PageHeader";
import BreadCrump from "../components/BreadCrump/BreadCrump";

const Order = () => {
    let page_name = "Как заказать?"; 

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
    return (
        <main className="container">
            <Header name={page_name}/>
            <BreadCrump link={page_name}/>
            <h3>Чтобы заказать у нас мягкую мебель, следуйте этим инструкциям:</h3>
            <ol className="list-group list-group-flush list-group-numbered mb-5">
                <li className="list-group-item"><strong>Посетите наш салон мебели:</strong> Загляните к нам в салон, чтобы ознакомиться с нашим ассортиментом мягкой мебели и выбрать подходящую модель.</li>
                <li className="list-group-item"><strong>Получите консультацию:</strong> Обратитесь к нашим специалистам, чтобы получить дополнительную информацию о доступных моделях, характеристиках, цветах и размерах мягкой мебели.</li>
                <li className="list-group-item"><strong>Выберите подходящую модель:</strong> Выберите модель мягкой мебели, которая соответствует вашим потребностям и предпочтениям. Учтите стиль интерьера, размер помещения и ваш бюджет.</li>
                <li className="list-group-item"><strong>Обсудите детали заказа:</strong> Обсудите с нашими специалистами детали заказа, включая выбранный цвет, материал, размер и дополнительные опции для мягкой мебели.</li>
                <li className="list-group-item"><strong>Оформите заказ:</strong> Подпишите договор на заказ мягкой мебели и внесите предоплату в соответствии с условиями договора.</li>
                <li className="list-group-item"><strong>Ожидайте изготовление:</strong> После оформления заказа мы начнем процесс изготовления вашей мягкой мебели. Срок изготовления может варьироваться в зависимости от выбранной модели. Обычно он составляет минимум 4 недели.</li>
                <li className="list-group-item"><strong>Доставка и установка:</strong> После завершения изготовления мы свяжемся с вами для организации доставки. Подробнее о доставке в разделе "Оплата и Доставка".</li>
                <li className="list-group-item"><strong>Оставьте отзыв:</strong> Мы будем рады получить ваш отзыв о процессе заказа и качестве обслуживания. Ваше мнение поможет нам улучшить наши услуги.</li>
                <li className="list-group-item"><strong>Наслаждайтесь вашей новой мягкой мебелью:</strong> Наслаждайтесь комфортом и красотой вашей новой мягкой мебели! Спасибо за ваш выбор.</li>
            </ol>
        </main>
    );
};

export default Order;